import React from 'react';
import {Link, graphql} from 'gatsby';
import {getImage, withArtDirection, StaticImage} from 'gatsby-plugin-image';
// Component imports
import Layout from '@components/structure/Layout.jsx';
import Hero from '@components/content/hero.jsx';
import Cards from '@components/content/cards.jsx';


import Quote from '@components/content/quote'
// Utility imports
import SEO from '@components/utility/SEO.jsx';

export const Head = () => (
	<>
	<script type='application/ld+json'
	 dangerouslySetInnerHTML={{
        __html: JSON.stringify({ 
            "@context":"http://schema.org",
            "@type" : "WebSite",
            "name" : "Aurinia Alliance",
            "url" : "https://www.auriniaalliance.com/"
		})
	}}
	/>
	</>
  )

const Index = ({data}) => {
	const heroImage = withArtDirection(
		getImage(data.heroLarge), [
		{
			media: "(max-width: 600px)",
			image: getImage(data.heroSmall),
		},
		{
			media: "(max-width: 1440px)",
			image: getImage(data.heroMedium),
		},

	])
	const signatureImage = getImage(data.signature);
	const jenniferPortrait = getImage(data.jenniferPortrait);
	const malishaPortrait = getImage(data.malishaPortrait);
	const aliciaPortrait = getImage(data.aliciaPortrait);

	return(
	<Layout pageClassName="index">
		<SEO title='Patient Services Site | Aurinia Alliance&reg;' description='Aurinia Alliance&reg; provides people living with lupus nephritis and their care partners with personalized support through education, tools, and resources. Enroll into the program now to get the benefits of Aurinia Alliance.' />
		<Hero heading={['For every lupus nephritis journey, Aurinia Alliance', <sup key='super'>&reg;</sup>, ' can help']} background={heroImage} signature={signatureImage} signatureDisc={[<p key='name'>Crystal S., RN</p>,<p key='ncm'>Nurse Case Manager</p>]}>
			<p>Aurinia Alliance provides people living with lupus nephritis and their care partners with personalized support through education, tools, and resources. As part of our program, you'll be assigned a dedicated Nurse Case Manager, who will help to ensure you have everything you need to feel confident and informed about your treatment plan.</p>
		</Hero>
		<section className="cta-row cta-row--sky-blue">
			<div className="wrapper">
				<div className="aa-icon__container__border">
					<div className="aa-icon__container">
						<StaticImage src='../images/aurinia-alliance-icon.png'  layout='fixed' width={73} quality={100} alt=''></StaticImage>
					</div>
				</div>
				<div className="enroll-or-call">
					<Link to='/enroll' className="btn btn--white">START ENROLLMENT NOW</Link>
					<span>OR</span>
					<a className="btn btn--white" href={'tel:1-833-287-4642'}>CALL 1-833-287-4642</a>
				</div>
				<p className='noMargin'>You can also download an enrollment form and return it by mail.</p>
				<p className='noMargin'><a href="/pdfs/patient-enrollment-form.pdf" target={'_blank'}>English Enrollment Form</a> or <a href="/pdfs/spanish-patient-enrollment-form.pdf" target={'_blank'}>Spanish Enrollment Form (Formulario de inscripción en español)</a></p>
			</div>
		</section>
		<section className="cta-row cta-row--white">
			<div className="wrapper">
				<h2>Here for you every step of the way</h2>
				<p>Your Aurinia Alliance team focuses on providing you with what you need throughout your Aurinia treatment journey. We understand that every experience with lupus nephritis is unique, which is why we're here to listen and support you. One key member of your team is your dedicated Nurse Case Manager. They provide one-on-one support and will check in with you on a regular basis to answer your questions and see how you are doing.</p>
			</div>
		</section>
		<Quote portrait={jenniferPortrait} patientInfo={"Jennifer, started treatment 2021"} position={'quote__container--left'} className={'quote--grey quote--navy-blue-text'} backgroundImage={true}>
			<p className='quote-text'>"I wouldn't be here without the support of my Aurinia Alliance Nurse Case Manager. She gave me information about my Aurinia treatment, answered my questions, and coordinated with my insurance and my doctors to help get coverage."</p>
		</Quote>
		<section className="treatment-journey">
			<div className="wrapper">
				<h2>What you can expect with Aurinia Alliance</h2>
			</div>
			<Cards/>
			<div className="wrapper">
				<Link to={'/enroll'} className='btn btn--purple'>ENROLL NOW</Link>
			</div>
		</section>
        <Quote portrait={malishaPortrait} patientInfo={"Malisha, started treatment in 2021"} position={'quote__container--left'} className={'quote--light-purple'} backgroundImage={false}>
			<p className='quote-text'>"My Nurse Case Manager called me and went over side effects, precautions, and dosing. She also helped me deal with the insurance approval issues so that I could get my prescription filled. She is absolutely awesome!"</p>
		</Quote>
		<Quote portrait={aliciaPortrait} patientInfo={"Alicia L., RN, BSN Nurse Case Manager"} position={'quote__container--right'} className={'quote--grey'} backgroundImage={true}>
			<p className='quote-text'>"We're able to personalize your Aurinia treatment experience. Rather than patients just filling a prescription, we help you become invested and empowered in your journey."</p>
		</Quote>
		<section className="did-you-know">
			<div className="wrapper wrapper--cards">
				<h2>Did you know?</h2>
				<div className="cards__container">
					<div className="card">
						<div className="flip-card-inner">
							<div className="flip-card-front">
								<StaticImage src='../images/person-icon.png' quality={90} alt='' className="card__img--front" style={{ top: "120px", left: "-40px" }}/>
								<p className="card__text--front">You'll have a dedicated Nurse Case Manager from the start</p>
								<button className="card__learn-more" onClick={(e)=>{e.target.closest('.card').classList.add('card--flipped')}}>Learn More</button>
							</div>
							<div className="flip-card-back" onClick={(e)=>{e.target.closest('.card').classList.remove('card--flipped')}}>
								<StaticImage src='../images/person-icon.png' quality={90} alt='' className="card__img"/>
								<p className="card__text">As soon as you enroll with Aurinia Alliance, you’ll be connected with a Nurse Case Manager who will provide customized support throughout your Aurinia treatment journey.</p>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="flip-card-inner">
							<div className="flip-card-front">
								<StaticImage src='../images/money-icon.png' quality={90} alt='' className="card__img--front" style={{ top: "-50px", right: "-50px" }}/>
								<p className="card__text--front">Many patients are eligible for financial support</p>
								<button className="card__learn-more" onClick={(e)=>{e.target.closest('.card').classList.add('card--flipped')}}>Learn More</button>
							</div>
							<div className="flip-card-back" onClick={(e)=>{e.target.closest('.card').classList.remove('card--flipped')}}>
								<StaticImage src='../images/money-icon.png' quality={90} alt='' className="card__img" />
								<p className="card__text">The cost of a prescription doesn’t have to stop you from getting treatment. Whether you have insurance or not, Aurinia Alliance has various funding programs that can help.</p>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="flip-card-inner">
							<div className="flip-card-front">
								<StaticImage src='../images/computer-icon.png' quality={90} alt='' className="card__img--front" style={{ top: "120px", right: "-50px" }}/>
								<p className="card__text--front">You can join a lupus nephritis community</p>
								<button className="card__learn-more" onClick={(e)=>{e.target.closest('.card').classList.add('card--flipped')}}>Learn More</button>
							</div>
							<div className="flip-card-back" onClick={(e)=>{e.target.closest('.card').classList.remove('card--flipped')}}>
								<StaticImage src='../images/computer-icon.png' quality={90} alt='' className="card__img"/>
								<p className="card__text">Aurinia Alliance can connect you with the lupus nephritis community, so you can share your experiences with others who are fighting the same disease.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="wrapper">
					<Link to={'/enroll'} className='btn btn--purple'>ENROLL NOW</Link>
				</div>
			</div>
		</section>
	</Layout>
	)
};

export default Index;

export const pageQuery = graphql`
  query HeroQuery {
    heroSmall: file(relativePath: {eq: "hero/home-hero--s.png"}) {
		childImageSharp {
		  gatsbyImageData(width:375)
		}
	},
	heroMedium: file(relativePath: {eq: "hero/home-hero--m.png"}) {
		childImageSharp {
		  gatsbyImageData(width:1440)
		}
	},	
	heroLarge: file(relativePath: {eq: "hero/home-hero--l.png"}) {
		childImageSharp {
		  gatsbyImageData(width:1500)
		}
	},
	signature: file(relativePath: {eq: "hero/home-signature.png"}) {
		childImageSharp {
		  gatsbyImageData(width:134)
		}
	},
	jenniferPortrait: file(relativePath: {eq: "portrait/jennifer.png"}) {
		childImageSharp {
		  gatsbyImageData(width:200)
		}
	},
    malishaPortrait: file(relativePath: {eq: "portrait/malisha.png"}) {
		childImageSharp {
		  gatsbyImageData(width:200)
		}
	},
	aliciaPortrait: file(relativePath: {eq: "portrait/alicia.png"}) {
		childImageSharp {
		  gatsbyImageData(width:200)
		}
	},
}
`
