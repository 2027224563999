import React from 'react'

export default function cards({children}){
        
        return (    
            <div className="cards">
                <div className="wrapper wrapper--cards cards__container">
                    <div className="card">
                        <img src={'/images/apple-book-icon.svg'} width="101" height="101" alt="" className="card__img"/>
                        <h3>Educational tools</h3>
                        <p className="card__text">Finding reliable information about lupus nephritis can be difficult. Our goal is to empower people living with lupus nephritis through education, so you can feel confident advocating for yourself. Your Nurse Case Manager will provide helpful resources and materials tailored to your specific needs.</p>
                    </div>
                    <div className="card">
                        <img src={'/images/financial-icon.svg'} width="101" height="101" alt="" className="card__img"/>
                        <h3>Financial assistance</h3>
                        <p className="card__text">Navigating a treatment plan for a serious condition can come with concerns about how to afford medication. We’ll work together to find solutions for your individual needs, and your Nurse Case Manager can answer questions about your insurance benefits and assistance you may be eligible for.</p>
                    </div>
                    <div className="card">
                        <img src={'/images/calendar-icon.svg'} width="101" height="101" alt="" className="card__img"/>
                        <h3>Aurinia treatment support</h3>
                        <p className="card__text">Living with a serious disease can feel like a lot to manage. We’ll help you stay organized, informed, and on top of your Aurinia treatment schedule. Your Nurse Case Manager can also provide you with a network of lupus nephritis advocacy groups to ensure you feel connected to a community of support.</p>
                    </div>
                </div>
            </div>
        );

}